<template>
  <div class="page">
    <router-view />
    <van-tabbar  @change="onChange" route active-color="#f0b82d">
      <van-tabbar-item to="/index" icon="home-o">
        <span>{{ $t('common.news') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.sy.active : icon.sy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/news" icon="search"
        ><span>{{ $t('common.home') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.hq.active : icon.hq.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/trade" icon="friends-o">
        <span>{{ $t('common.trade') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.jy.active : icon.jy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/assets" icon="setting-o">
        <span>{{ $t('common.assets') }}</span>
        <template #icon="props">
          <img
            :src="props.active ? icon.zc.active : icon.zc.inactive"
          /> </template
      ></van-tabbar-item>
      <van-tabbar-item to="/my" icon="friends-o">
        <span>{{ $t('common.mine') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.my.active : icon.my.inactive" />
        </template>
        <span v-if="isMsg" class="myMgs"></span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        sy: {
          active: require('../assets/img/sy-xz.png'),
          inactive: require('../assets/img/sy.png')
        },
        hq: {
          active: require('../assets/img/hq-xz.png'),
          inactive: require('../assets/img/hq.png')
        },
        jy: {
          active: require('../assets/img/jy-xz.png'),
          inactive: require('../assets/img/jy.png')
        },
        zc: {
          active: require('../assets/img/zc-xz.png'),
          inactive: require('../assets/img/zc.png')
        },
        my: {
          active: require('../assets/img/my-xz.png'),
          inactive: require('../assets/img/my.png')
        }
      },
      isMsg:false,//是否显示未读
    }
  },
  created() {
    this.getMsg();
  },
  methods: {
    onChange(e){
      this.getMsg();
    },
      //获取未读消息
    async getMsg() {
      const { data } = await this.$http.get("/home/user/unread");
      if (data) {
        if (data.code === 200) {
          this.isMsg = data.data;
        }
      }
    },
  }
}
</script>
<style lang="less">
.page {
  background: #f7f7f7;
}
.van-tabbar {
  height: 3.33333rem;
  background-color: #fff;
  border-top: 0.02667rem solid #e5e5e5;
  z-index: 99;
  .van-tabbar-item img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.van-tabbar-item{
  position: relative;
}

.myMgs{
  position: absolute;
  right: 20px;
  top: 5px;
  min-width: 6px;
  min-height: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #F42A2A;
}
</style>
