import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";

Vue.use(VueRouter);
//定义一个函数判断是手机端还是pc端
function isMobile() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true; // 移动端
  } else {
    return false; // PC端
  }
}

// 针对ElementUI导航栏中重复导航报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // 登录页
  { path: "/login", component: () => import("@/views/index/Login.vue") },
  // 注册页
  { path: "/register", component: () => import("@/views/index/Register.vue") },
  // 忘记密码
  { path: "/forget", component: () => import("@/views/index/Forget.vue") },
  // 登录页的用户协议
  {
    path: "/agreement",
    component: () => import("@/views/index/Agreement.vue"),
  },
  // web页
  {
    path: "/web/my/index",
    component: () => import("@/views/web/my/index.vue"),
  },
  {
    path: "/web/helpCenter/index",
    component: () => import("@/views/web/helpCenter/index.vue"),
  },
  {
    path: "/web/assets/index",
    component: () => import("@/views/web/assets/index.vue"),
  },
  {
    path: "/web/index/index",
    component: () => import("@/views/web/index/index.vue"),
  },
  {
    path: "/web/index/login",
    component: () => import("@/views/web/index/Login.vue"),
  },
  {
    path: "/web/index/Register",
    component: () => import("@/views/web/index/Register.vue"),
  },
  {
    path: "/web/index/Forget",
    component: () => import("@/views/web/index/Forget.vue"),
  },
  {
    path: "/web/index/Agreement",
    component: () => import("@/views/web/index/Agreement.vue"),
  },
  {
    path: "/web/index/Terms",
    component: () => import("@/views/web/index/Terms.vue"),
  },
  {
    path: "/web/trade/LeveragedTrading",
    component: () => import("@/views/web/trade/LeveragedTrading.vue"),
  },
  {
    path: "/web/trade/FuturesTrading",
    component: () => import("@/views/web/trade/FuturesTrading.vue"),
  },
  {
    path: "/web/trade/LockUpMining",
    component: () => import("@/views/web/trade/LockUpMining.vue"),
  },
  // 联系列表页
  {
    path: "/web/my/Contact_list",
    component: () => import("@/views/web/my/Contact_list.vue"),
  },
  // 设置
  {
    path: "/web/my/setting",
    component: () => import("@/views/web/my/Setting.vue"),
  },
  // 注册页的服务条款
  { path: "/terms", component: () => import("@/views/index/Terms.vue") },
  // 状态栏四个导航
  {
    path: "/",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: "/index",
        component: () => import("@/views/index/Index.vue"),
      },
      {
        path: "/news",
        component: () => import("@/views/news/Index.vue"),
      },
      {
        path: "/trade_:id",
        component: () => import("@/views/trade/Index.vue"),
      },
      {
        path: "/trade",
        component: () => import("@/views/trade/Index.vue"),
      },
      {
        path: "/assets",
        component: () => import("@/views/assets/Index.vue"),
      },
      {
        path: "/my",
        component: () => import("@/views/my/Index.vue"),
      },
    ],
  },

  // 使用教程
  {
    path: "/course",
    component: () => import("@/views/course/Index.vue"),
  },
  {
    path: "/course/detail/:id",
    component: () => import("@/views/course/Detail.vue"),
  },
  // person 设置页
  {
    path: "/person/setting",
    component: () => import("@/views/person/Setting"),
  },
  // paeson 修改登录密码
  {
    path: "/person/setting/changepassword",
    component: () => import("@/views/person/Changepassword.vue"),
  },
  // paeson 修改支付密码
  {
    path: "/person/setting/paypassword",
    component: () => import("@/views/person/PayPassword.vue"),
  },
  // person 联系方式
  {
    path: "/person/setting/bank",
    component: () => import("@/views/person/Bank.vue"),
  },
  // person 认证页
  {
    path: "/person/authentication",
    component: () => import("@/views/person/Authentication"),
  },
  // person 身份认证
  {
    path: "/person/identity",
    component: () => import("@/views/person/Identity"),
  },
  // person 高级认证
  {
    path: "/person/advanced",
    component: () => import("@/views/person/Advanced"),
  },
  // 理财首页
  {
    path: "/fund",
    component: () => import("@/views/fund/Index.vue"),
  },
  // 理财 产品介绍
  {
    path: "/fund/introduction",
    component: () => import("@/views/fund/Introduction.vue"),
  },
  // 理财 产品详情
  {
    path: "/fund/introduction/detail/:id",
    component: () => import("@/views/fund/IntroductionDetail.vue"),
  },
  // 理财 投资发布
  {
    path: "/fund/investment",
    component: () => import("@/views/fund/Investment.vue"),
  },
  // 理财 投资发布详情页
  {
    path: "/fund/investment/detail/:id",
    component: () => import("@/views/fund/InvestmentDetail.vue"),
  },
  // 理财 投资标池
  {
    path: "/fund/pool",
    component: () => import("@/views/fund/Pool.vue"),
  },
  // 理财 投资标池详情
  {
    path: "/fund/pool/detail/:id",
    component: () => import("@/views/fund/PoolDetail.vue"),
  },
  // 新闻详情页
  {
    path: "/news/detail/:id",
    component: () => import("@/views/news/Detail.vue"),
  },
  // 资产 充币页
  {
    path: "/assets/recharge",
    component: () => import("@/views/assets/Recharge.vue"),
  },
  // 资产 提币页
  {
    path: "/assets/withdraw",
    component: () => import("@/views/assets/Withdraw.vue"),
  },
  // 资产 银行卡
  {
    path: "/assets/card/:selete",
    component: () => import("@/views/assets/Card.vue"),
  },
  // 资产 添加银行卡
  {
    path: "/assets/addcard",
    component: () => import("@/views/assets/AddCard.vue"),
  },
  // 资产 划转页
  {
    path: "/assets/transfer",
    component: () => import("@/views/assets/Transfer.vue"),
  },
  // 资产 兑换页
  {
    path: "/assets/conversion",
    component: () => import("@/views/assets/Conversion.vue"),
  },
  // 法币交易页
  {
    path: "/trade/shopping",
    component: () => import("@/views/trade/Shopping.vue"),
  },
  // 我要卖页
  {
    path: "/trade/shopping/sell",
    component: () => import("@/views/trade/Sell.vue"),
  },
  // 交易记录
  {
    path: "/trade/shopping/trading/:type",
    component: () => import("@/views/trade/Trading.vue"),
  },
  // 购买交易详情
  {
    path: "/trade/shopping/trading/buy_detail/:id",
    component: () => import("@/views/trade/Buy_detail.vue"),
  },
  // 购买付款页面
  {
    path: "/trade/shopping/trading/buy_pay/:id",
    component: () => import("@/views/trade/Buy_pay.vue"),
  },
  // 联系卖家页面
  {
    path: "/trade/shopping/trading/contact/:id",
    component: () => import("@/views/trade/Contact.vue"),
  },
  // 确认出售详情
  {
    path: "/trade/shopping/trading/sell_detail/:id",
    component: () => import("@/views/trade/Sell_detail.vue"),
  },
  // 联系列表页
  {
    path: "/trade/contact/list",
    component: () => import("@/views/trade/Contact_list.vue"),
  },
  // SGR邀请
  {
    path: "/trade/sgrinvite",
    component: () => import("@/views/trade/SgrInvite.vue"),
  },
  // 钱包地址
  {
    path: "/assets/WalletAddress/:selete",
    component: () => import("@/views/assets/WalletAddress.vue"),
  },
  // c2c充值列表
  {
    path: "/assets/WithdrawList",
    component: () => import("@/views/assets/WithdrawList.vue"),
  },
  // c2c充值详情
  {
    path: "/assets/WithdrawDetails/:id",
    component: () => import("@/views/assets/WithdrawDetails.vue"),
  },
  // c2c充值
  {
    path: "/assets/WithdrawPay/:id",
    component: () => import("@/views/assets/WithdrawPay.vue"),
  },
  // c2c提现
  {
    path: "/assets/C2CWithdrawal/:id",
    component: () => import("@/views/assets/C2CWithdrawal.vue"),
  },
  // c2c提现列表
  {
    path: "/assets/C2CWithdrawalList",
    component: () => import("@/views/assets/C2CWithdrawalList.vue"),
  },
  // c2c提现详情
  {
    path: "/assets/C2CWithdrawalInfo/:id",
    component: () => import("@/views/assets/C2CWithdrawalInfo.vue"),
  },
  // 矿机
  {
    path: "/Lockup",
    component: () => import("@/views/news/Lockup.vue"),
  },
  { // 理财产品
    path: "/Lockup/Financial",
    component: () => import("@/views/news/FinancialList.vue"),
  },
  { // 购买
    path: "/Lockup/Purchase/:id",
    component: () => import("@/views/news/Purchase.vue"),
  },
  {// 委托订单
    path: "/Lockup/subscribe",
    component: () => import("@/views/news/Subscribe.vue"),
  },
  //订单详情
  {
    path: "/news/OrderDeyail",
    component: () => import("@/views/news/OrderDeyail.vue")
  },
   //贷款详情
   {
    path: "/person/Loan",
    component: () => import("@/views/person/loan.vue")
  },
  { // 贷款申请
    path: "/person/Apply/:id",
    component: () => import("@/views/person/LoanApply.vue"),
  },
  { // 贷款列表
    path: "/person/loanList",
    component: () => import("@/views/person/loanList.vue"),
  },
  { // 贷款协议
    path: "/person/loanagreement",
    component: () => import("@/views/person/LoanAgreement.vue"),
  },
  { // 错误页面
    path: "/error/error",
    component: () => import("@/views/error/error.vue"),
  },
  { // 错误页面
    path: "/error/error2",
    component: () => import("@/views/error/error2.vue"),
  },
];

const router = new VueRouter({
  routes,
});
// 路由守卫
router.beforeEach((to, from, next) => {
  // 如果访问登录页 直接放行
  if (
    to.path === "/login" ||
    to.path === "/register" ||
    to.path === "/forget" ||
    to.path === "/agreement" ||
    to.path === "/terms" ||
    to.path === "/error/error"||
    to.path === "/error/error2"
    // to.path === "/web/index/login" ||
    // to.path === "/web/index/register" ||
    // to.path === "/web/index/forget" ||
    // to.path === "/web/index/agreement" ||
    // to.path === "/web/index/terms"
  ) {
    return next();
  }
  // 获取token
  const tokenstr = window.localStorage.getItem("token");
  if (tokenstr) {
    next();
  } else {
    
    // 判断后的操作
    // if (isMobile()) {
      next(`/login?redirect=${to.path}`);
      // 判断true跳转到这个主页
    // } else {
    //   next(`/web/index/login?redirect=${to.path}`);
    //   // 判断false跳转到这个主页
    // }

  }
});

export default router;
